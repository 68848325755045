import * as React from "react";
import * as Types from "../socket/types";
import { Theme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import SyncIcon from "@mui/icons-material/Sync";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowModel, zhCN } from "@mui/x-data-grid";
import { connect } from "react-redux";
import { RootDispatch, RootState } from "../store";
import { withRoot } from "../withRoot";
import Link from "./component/link";
import { getZMXLink } from "../common/getlink";

const styles = (theme: Theme) => {
    return {
        fab: {
            position: "absolute",
            bottom: theme.spacing(10),
            left: "50%",
            marginLeft: -28,
        },
        dialog: {
            width: 500,
        },
    };
};

const mapState = (state: RootState) => ({
    columns: state.zmx.columns,
    dialogOpen: state.zmx.dialogOpen,
    resources: state.zmx.resources,
    selectedResource: state.zmx.selectedResource,
    promiseArguments: state.zmx.promiseArguments,
});

const mapDispatch = (dispatch: RootDispatch) => ({
    sZMXColumn: (columns: GridColDef[]) => dispatch.zmx.sColumn(columns),
    sPromiseArguments: (promiseArguments: any) => dispatch.zmx.sPromiseArguments(promiseArguments),
    openDialog: () => dispatch.zmx.openDialog(),
    closeDialog: () => dispatch.zmx.closeDialog(),
    subscribezmx: (f?: () => void) => dispatch.zmx.subscribe(f),
    unsubscribezmx: (f?: () => void) => dispatch.zmx.unSubscribe(f),
    sName: (e: string) => dispatch.zmx.sName(e),
    resourceInsert: (e: Types.ZMXResource) => dispatch.zmx.insert(e),
    resourceUpdate: () => dispatch.zmx.update(null),
    resourceRemove: (id: string) => dispatch.zmx.remove(id),
    sTitle: (title: string) => dispatch.page.sTitle(title),
    sSubTitle: (subtitle: string) => dispatch.page.sSubTitle(subtitle),
    scrapOnce: (name?: string) => dispatch.zmx.scrapOnce(name),
});

interface IZMXPageProps {
    classes?: any;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IZMXPageProps;

@withRoot(styles)
class ZMXPage extends React.Component<connectedProps> {
    private yesButtonRef = React.createRef<HTMLButtonElement>();

    constructor(p: connectedProps) {
        super(p);

        this.props.subscribezmx(this.zmxChanged);

        this.props.sTitle("ZMX订阅");
        this.props.sSubTitle("");
    }

    private zmxChanged = () => {
        // if (this.props.columns.length === 0) {
        this.createColumn();
        // }
    };

    createColumn = () => {
        const columns: GridColDef[] = [
            {
                field: "name",
                headerName: "资源名称",
                width: 400,
                renderCell: (params) => <Link href={getZMXLink(params.row)}>{params.value}</Link>,
                editable: true,
            },
            {
                field: "lastUpadedTime",
                headerName: "上次更新时间",
                flex: 1,
                minWidth: 250,
            },
            {
                field: "actions",
                type: "actions",
                width: 80,
                renderHeader: (params) => {
                    return (
                        <IconButton color="primary" onClick={() => this.handleScrapOnce()}>
                            <SyncIcon />
                        </IconButton>
                    );
                },
                getActions: (params) => [
                    <GridActionsCellItem icon={<SyncIcon color="primary" />} label="Sync" onClick={() => this.handleScrapOnce(params.row.name)} showInMenu={false} onResize={() => { }} onResizeCapture={() => { }} />,
                    <GridActionsCellItem icon={<DeleteIcon color="primary" />} label="Delete" onClick={() => this.handleUnSubscribe(params.id)} showInMenu={false} onResize={() => { }} onResizeCapture={() => { }} />,
                ],
            } as any,
        ];

        this.props.sZMXColumn(columns);
    };

    handleClickOpen = () => {
        this.props.openDialog();
    };

    handleSubscribe = () => {
        this.props.resourceInsert(this.props.selectedResource);

        this.props.closeDialog();
    };

    handleUnSubscribe = (id) => {
        this.props.resourceRemove(id);
    };

    handleScrapOnce = (name?: string) => {
        this.props.scrapOnce(name);
    };

    handleClose = () => {
        this.props.closeDialog();
    };

    nameChanged = (e) => {
        this.props.sName(e.target.value);
    };

    processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) =>
        new Promise<GridRowModel>((resolve, reject) => {
            const mutation = this.computeMutation(newRow, oldRow);
            if (mutation) {
                this.props.sPromiseArguments({ resolve, reject, newRow, oldRow });
            } else {
                resolve(oldRow);
            }
        });

    computeMutation = (newRow: GridRowModel, oldRow: GridRowModel) => {
        if (newRow.name !== oldRow.name) {
            return `资源名称从'${oldRow.name}'修改为'${newRow.name}'`;
        }
        return null;
    };

    handleNo = () => {
        const { oldRow, resolve } = this.props.promiseArguments;
        resolve(oldRow); // Resolve with the old row to not update the internal state
        this.props.sPromiseArguments(null);
    };

    handleYes = async () => {
        const { newRow, oldRow, reject, resolve } = this.props.promiseArguments;

        try {
            this.props.resourceUpdate();
            this.props.sPromiseArguments(null);
        } catch (error) {
            reject(oldRow);
            this.props.sPromiseArguments(null);
        }
    };

    handleEntered = () => {
        // The `autoFocus` is not used because, if used, the same Enter that saves
        // the cell triggers "否". Instead, we manually focus the "否" button once
        // the dialog is fully open.
        this.yesButtonRef.current?.focus();
    };

    componentWillUnmount() {
        this.props.unsubscribezmx();
    }

    render() {
        const { classes, columns, resources, promiseArguments } = this.props;

        const { newRow, oldRow } = !!promiseArguments ? promiseArguments : { newRow: "", oldRow: "" };
        const mutation = !!promiseArguments && this.computeMutation(newRow, oldRow);

        return (
            <>
                <DataGrid
                    rows={resources}
                    columns={columns}
                    pageSize={25}
                    rowsPerPageOptions={[25]}
                    disableSelectionOnClick
                    localeText={zhCN.components.MuiDataGrid.defaultProps.localeText}
                    experimentalFeatures={{ newEditingApi: true }}
                    className={classes.datagrid}
                    processRowUpdate={this.processRowUpdate}
                />
                <Fab color="secondary" aria-label="Add" className={classes.fab} onClick={this.handleClickOpen}>
                    <AddIcon />
                </Fab>
                <Dialog open={this.props.dialogOpen} onClose={this.handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle>订阅</DialogTitle>
                    <DialogContent className={classes.dialog} dividers>
                        <TextField autoFocus margin="dense" id="name" label="资源名称" type="text" fullWidth onChange={this.nameChanged} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            取消
                        </Button>
                        <Button onClick={this.handleSubscribe} color="primary">
                            订阅
                        </Button>
                    </DialogActions>
                </Dialog>
                {!!promiseArguments && (
                    <Dialog maxWidth="xs" fullWidth TransitionProps={{ onEntered: this.handleEntered }} open={!!promiseArguments}>
                        <DialogTitle>确认</DialogTitle>
                        <DialogContent dividers>{`${mutation}`}</DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleNo}>否</Button>
                            <Button ref={this.yesButtonRef} onClick={this.handleYes}>
                                是
                            </Button>
                        </DialogActions>
                    </Dialog>
                )}
            </>
        );
    }
}

export default connect(mapState, mapDispatch)(ZMXPage);
