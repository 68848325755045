import * as React from "react";
import { Theme, CSSObject } from "@mui/material/styles";
import { grey } from "@mui/material/colors";
import MuiDrawer, { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import { connect } from "react-redux";
import { RootDispatch, RootState } from "../../store";
import { withRoot } from "../../withRoot";
import classNames from "classnames";

export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: grey[900],
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
});

const closedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: grey[900],
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
    backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05))",
});

const styles = (theme: Theme) => ({
    root: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
    },
    open: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    },
    close: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    },
});

const mapState = (state: RootState) => ({
    drawOpen: state.page.drawOpen,
});

const mapDispatch = (dispatch: RootDispatch) => ({
    //
});

interface IDrawerProps extends MuiDrawerProps {
    classes?: any;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IDrawerProps;

@withRoot(styles)
class Drawer extends React.Component<connectedProps> {
    constructor(p: connectedProps) {
        super(p);
    }

    render() {
        const { classes } = this.props;

        return (
            <MuiDrawer variant="permanent" className={classNames(classes.root, this.props.drawOpen ? classes.open : classes.close)}>
                {this.props.children}
            </MuiDrawer>
        );
    }
}

export default connect(mapState, mapDispatch)(Drawer);
