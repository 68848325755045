import * as React from "react";
import { Theme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { connect } from "react-redux";
import { RootDispatch, RootState } from "../../store";
import { withRoot } from "../../withRoot";

const styles = (theme: Theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
});

const mapState = (state: RootState) => ({
    //
});

const mapDispatch = (dispatch: RootDispatch) => ({
    closeDraw: () => dispatch.page.closeDraw(),
});

interface IDrawerHeaderProps {
    classes?: any;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IDrawerHeaderProps;

@withRoot(styles)
class DrawerHeader extends React.Component<connectedProps> {
    constructor(p: connectedProps) {
        super(p);
    }

    handleDrawerClose = () => {
        this.props.closeDraw();
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <IconButton onClick={this.handleDrawerClose}>
                    <ChevronLeftIcon />
                </IconButton>
            </div>
        );
    }
}

export default connect(mapState, mapDispatch)(DrawerHeader);
