import * as Types from "../socket/types";
import { cast, server } from "../socket/socketClient";
import { createModel } from "@rematch/core";
import { Disposable } from "../common/events";
import type { RootModel } from '.';

export type AliyunState = {
    //
};

export const aliyun = createModel<RootModel>()({
    state: {
        //
    },
    reducers: {
        //
    },
    effects: (dispatch) => ({
        // async postMessage() {
        //     await server.postMessage({}).then(e => {
        //         console.log(e);
        //     });
        // }
    }),
});
