import * as contract from "./socketContract";
import * as slc from "../socketLib/socketLibClient";
import * as Client from "./client";

// Ensure that the namespace follows the contract
var _checkTypes: typeof contract.client = Client;
// launch client
export let { server, cast, pendingRequestsChanged, connectionStatusChanged } = slc.run({
    clientImplementation: Client,
    serverContract: contract.server,
    cast: contract.cast
});

// Sample usage
// cast.hello.on(p => {
//     console.log(p);
// });
