import * as React from "react";
import { Theme } from "@mui/material/styles";
import { green, grey } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import HomeIcon from "@mui/icons-material/Home";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import { connect } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import AppBar from "./component/appbar";
import Drawer from "./component/drawer";
import DrawerHeader from "./component/drawerheader";
import ListItemLink from "./component/listitemlink";
import { RootDispatch, RootState } from "../store";
import { withRoot } from "../withRoot";
import LinkPage from "./linkpage";
import ZMXPage from "./zmxpage";
import ZMZPage from "./zmzpage";
import DMHYPage from "./dmhypage";
import CommonPage from "./commonpage";
import McarPage from "./mcarpage";

const styles = (theme: Theme) => ({
    root: {
        display: "flex",
        width: "100%",
    },
    process: {
        width: "100%",
        position: "absolute",
        height: 1,
        zIndex: 9999,
    },
    menuButton: {
        marginRight: 5,
    },
    hide: {
        display: "none",
    },
    main: {
        flexGrow: 1,
        paddingTop: 94,
        paddingBottom: 24,
        paddingLeft: 24,
        paddingRight: 24,
    },
    routesPaper: {
        height: "100%",
        backgroundColor: grey[900],
    },
    messageSuccess: {
        color: theme.palette.text.primary,
        backgroundColor: green[600],
    },
    messageError: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.error.dark,
    },
    messageInfo: {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.info.dark,
    },
});

const mapState = (state: RootState) => ({
    loading: {
        global: (state as any).loading.global,
    },
    messageInfo: state.message.messageInfo,
    messageOpen: state.message.open,
});

const mapDispatch = (dispatch: RootDispatch) => ({
    subscribe: () => dispatch.page.subscribe(),
    closeMessage: () => dispatch.message.close(),
    processQueueMessage: () => dispatch.message.processQueue(),
});

interface IIndexPageProps {
    classes?: any;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IIndexPageProps;

@withRoot(styles)
export class Index extends React.Component<connectedProps> {
    constructor(p: connectedProps) {
        super(p);

        this.props.subscribe();
    }

    handleMessageClose = () => {
        this.props.closeMessage();
    };

    handleMessageExited = () => {
        this.props.processQueueMessage();
    };

    render() {
        const { classes } = this.props;

        return (
            <Box className={classes.root}>
                {!!this.props.loading.global && <LinearProgress className={classes.process} />}
                <AppBar />
                <Drawer>
                    <DrawerHeader />
                    <Divider />
                    <List>
                        <ListItemLink to="/" primary="Home" icon={<HomeIcon />} />
                    </List>
                    <Divider />
                    <List>
                        <ListItemLink to="/zmz" primary="ZMZ" color="secondary" letter="Z" />
                        <ListItemLink to="/zmx" primary="ZMX" color="warning" letter="X" />
                        <ListItemLink to="/dmhy" primary="DMHY" color="error" letter="D" />
                        <ListItemLink to="/mcar" primary="Mcar" color="success" letter="M" />
                        <ListItemLink to="/common" primary="Common" color="info" letter="C" />
                    </List>
                </Drawer>
                <Box component="main" className={classes.main}>
                    <Paper className={classes.routesPaper}>
                        <Routes>
                            <Route path="/" element={<LinkPage />} />
                            <Route path="zmz" element={<ZMZPage />} />
                            <Route path="dmhy" element={<DMHYPage />} />
                            <Route path="zmx" element={<ZMXPage />} />
                            <Route path="mcar" element={<McarPage />} />
                            <Route path="common" element={<CommonPage />} />
                        </Routes>
                    </Paper>
                </Box>
                <Snackbar
                    key={this.props.messageInfo.key}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    open={this.props.messageOpen}
                    autoHideDuration={2000}
                    onClose={this.handleMessageClose}
                    TransitionProps={{
                        onExited: this.handleMessageExited,
                    }}
                    ContentProps={{
                        "aria-describedby": "message-id",
                    }}
                >
                    <SnackbarContent
                        className={classes[`message${this.props.messageInfo.type}`]}
                        message={<span id="message-id">{this.props.messageInfo.message}</span>}
                        action={
                            <>
                                <IconButton aria-label="close" color="inherit" sx={{ p: 0.5 }} onClick={this.handleMessageExited}>
                                    <CloseIcon />
                                </IconButton>
                            </>
                        }
                    />
                </Snackbar>
            </Box>
        );
    }
}

export default connect(mapState, mapDispatch)(Index);
