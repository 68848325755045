import * as React from "react";
import { Theme } from "@mui/material/styles";
import { connect } from "react-redux";
import { RootDispatch, RootState } from "../../store";
import { withRoot } from "../../withRoot";

const styles = (theme: Theme) => ({
    root: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        color: "inherit"
    },
});

const mapState = (state: RootState) => ({
    //
});

const mapDispatch = (dispatch: RootDispatch) => ({
    closeDraw: () => dispatch.page.closeDraw(),
});

interface IDrawerHeaderProps {
    classes?: any;
    href?: string;
    value?: string;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IDrawerHeaderProps;

@withRoot(styles)
class Link extends React.Component<connectedProps> {
    constructor(p: connectedProps) {
        super(p);
    }

    handleDrawerClose = () => {
        this.props.closeDraw();
    };

    render() {
        const { classes, href, children } = this.props;

        return (
            <a className={classes.root} target="_blank" href={href}>{children}</a>
        );
    }
}

export default connect(mapState, mapDispatch)(Link);
