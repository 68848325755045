import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import hoistNonReactStatics from "hoist-non-react-statics";
import { connect } from "react-redux";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { withStyles } from "@mui/styles";
import { createRef } from "react";
import { RootDispatch, RootState } from "./store";
import { extend } from "lodash-es";

export interface ContextComponent<Props> extends React.Component<Props> {
    getDecoratedComponentInstance(): React.Component<Props>;
}

export interface RootContext<P> {
    //
}

export function createChildContext<RootContext>(context?: RootContext) {
    return extend({}, context);
}

export interface RootContextProviderProps<BackendFactory> {
    context?: BackendFactory;
}

const mapState = (state: RootState) => ({
    theme: state.theme.value,
});

const mapDispatch = (dispatch: RootDispatch) => ({});

interface IZMZPageProps {
    classes?: any;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IZMZPageProps;

export function withRoot(styles?: any, rootContext?: any) {
    const childContext = createChildContext(rootContext);

    return function decorateContext<TargetClass extends React.ComponentClass<any>>(DecoratedComponent: TargetClass): TargetClass & ContextComponent<any> {
        const Decorated = withStyles(styles)(DecoratedComponent) as any;
        const displayName = Decorated.displayName || Decorated.name || "Component";

        class RootContextContainer extends React.Component<connectedProps> implements ContextComponent<any> {
            public static DecoratedComponent = DecoratedComponent;
            public static displayName = `WithRootContext(${displayName})`;

            private ref: React.RefObject<any> = createRef();

            // 声明Context对象属性
            static childContextTypes = {};

            // 返回Context对象，方法名是约定好的
            getChildContext() {
                return childContext;
            }

            public getDecoratedComponentInstance() {
                return this.ref.current;
            }

            public render() {
                const { theme, ...others } = this.props;

                return (
                    <StyledEngineProvider injectFirst>
                        <ThemeProvider theme={theme}>
                            <CssBaseline />
                            <Decorated {...others} ref={this.ref} />
                        </ThemeProvider>
                    </StyledEngineProvider>
                );
            }
        }

        const RootContextContainerConnector = connect(mapState, mapDispatch)(RootContextContainer);

        return hoistNonReactStatics(RootContextContainerConnector, DecoratedComponent) as TargetClass & RootContextContainer;
    };
}
