import * as React from "react";
import classNames from "classnames";
import { alpha, Theme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";
import SearchIcon from "@mui/icons-material/Search";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import SyncIcon from "@mui/icons-material/Sync";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Button from "@mui/material/Button";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { connect } from "react-redux";
import { RootDispatch, RootState, select } from "../../store";
import { withRoot } from "../../withRoot";
import { drawerWidth } from "./drawer";

const styles = (theme: Theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        position: "fixed",
        backgroundColor: blue[800],
    },
    open: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        "&:hover": {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            width: "auto",
        },
    },
    searchIconWrapper: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    searchInputBase: {
        color: "inherit",
        "& .MuiInputBase-input": {
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)})`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "50ch",
            },
        },
    },
    title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        position: "absolute",
        width: 200,
        left: "50%",
        marginLeft: -100,
        top: 5,
    },
    subtitle: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
        position: "absolute",
        width: 200,
        left: "50%",
        marginLeft: -100,
        bottom: 5,
    },
});

const mapState = (state: RootState) => ({
    drawOpen: state.page.drawOpen,
    pageTitle: state.page.title,
    pageSubTitle: state.page.subtitle,
    count: select.link.count(state),
    confirmOpen: state.page.confirmOpen,
});

const mapDispatch = (dispatch: RootDispatch) => ({
    openDraw: () => dispatch.page.openDraw(),
    closeDraw: () => dispatch.page.closeDraw(),
    openUsedAllConfirm: () => dispatch.page.openUsedAllConfirm(),
    closeUsedAllConfirm: () => dispatch.page.closeUsedAllConfirm(),
    openDropLinkConfirm: () => dispatch.page.openDropLinkConfirm(),
    closeDropLinkConfirm: () => dispatch.page.closeDropLinkConfirm(),
    usedAllLink: () => dispatch.link.usedAllLink(),
    dropLink: () => dispatch.link.dropCollection(),
    scrapAllOnce: () => dispatch.page.scrapAllOnce(),
});

interface IAppBarProps extends MuiAppBarProps {
    classes?: any;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IAppBarProps;

@withRoot(styles)
class AppBar extends React.Component<connectedProps> {
    constructor(p: connectedProps) {
        super(p);
    }

    handleDrawer = () => {
        this.props.drawOpen ? this.props.closeDraw() : this.props.openDraw();
    };

    handleUsedAllConfirmOpen = () => {
        this.props.openUsedAllConfirm();
    };

    handleUsedAllConfirmClose = () => {
        this.props.closeUsedAllConfirm();
    };

    handleUsedAllConfirm = () => {
        this.props.usedAllLink();
        this.props.closeUsedAllConfirm();
    };

    handleDropLinkConfirmOpen = () => {
        this.props.openDropLinkConfirm();
    };

    handleDropLinkConfirmClose = () => {
        this.props.closeDropLinkConfirm();
    };

    handleDropLinkConfirm = () => {
        this.props.dropLink();
        this.props.closeDropLinkConfirm();
    };

    handleScrapeOnce = () => {
        this.props.scrapAllOnce();
    };

    render() {
        const { classes } = this.props;

        return (
            <>
                <Dialog maxWidth="xs" fullWidth open={this.props.confirmOpen.usedAll} onClose={this.handleUsedAllConfirmClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle>确认</DialogTitle>
                    <DialogContent className={classes.dialog} dividers>
                        确认标记全部链接为使用?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleUsedAllConfirmClose} color="primary">
                            取消
                        </Button>
                        <Button onClick={this.handleUsedAllConfirm} color="primary" autoFocus>
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog maxWidth="xs" fullWidth open={this.props.confirmOpen.dropLink} onClose={this.handleDropLinkConfirmClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle>确认</DialogTitle>
                    <DialogContent className={classes.dialog} dividers>
                        删除Links表?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDropLinkConfirmClose} color="primary">
                            取消
                        </Button>
                        <Button onClick={this.handleDropLinkConfirm} color="primary" autoFocus>
                            确认
                        </Button>
                    </DialogActions>
                </Dialog>
                <MuiAppBar className={classNames(classes.root, this.props.drawOpen && classes.open)} color="primary">
                    <Toolbar>
                        <IconButton color="inherit" aria-label="open drawer" onClick={this.handleDrawer} edge="start" className={classNames(classes.menuButton, this.props.drawOpen && classes.hide)}>
                            <MenuIcon />
                        </IconButton>
                        <div className={classes.search}>
                            <div className={classes.searchIconWrapper}>
                                <SearchIcon />
                            </div>
                            <InputBase className={classes.searchInputBase} placeholder="Search…" inputProps={{ "aria-label": "search" }}></InputBase>
                        </div>
                        <Typography className={classes.title} variant="h6" color="inherit" noWrap align="center">
                            {this.props.pageTitle}
                        </Typography>
                        <Typography className={classes.subtitle} variant="subtitle2" color="inherit" noWrap align="center">
                            {this.props.pageSubTitle}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: "none", md: "flex" } }}>
                            <IconButton color="inherit" onClick={this.handleUsedAllConfirmOpen}>
                                <Badge className={classes.margin} badgeContent={this.props.count} color="secondary" overlap="rectangular">
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton>
                            <IconButton color="inherit" onClick={this.handleScrapeOnce}>
                                <SyncIcon />
                            </IconButton>
                            <IconButton color="inherit" onClick={this.handleDropLinkConfirmOpen}>
                                <DeleteSweepIcon />
                            </IconButton>
                            {/* <IconButton color="inherit" onClick={this.handleInvertColors}>
                            <InvertColorsIcon />
                        </IconButton> */}
                        </Box>
                    </Toolbar>
                </MuiAppBar>
            </>
        );
    }
}

export default connect(mapState, mapDispatch)(AppBar);
