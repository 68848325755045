import * as Types from "./types";
import { QRFunction, QRServerFunction, TypedEvent } from "../socketLib/socketLib";
import { MessageInfo } from "../common/message";

/**
 * Consists of the following contracts
 *
 * a contract on how the client --calls--> server
 * a contract on how the server --calls--> the client that is calling the server
 * a contract on how the server --anycasts-> all clients
 */
export var server = {
    echo: {} as QRServerFunction<{ text: string; num: number }, { text: string; num: number }, typeof client>,

    zmzRefresh: {} as QRFunction<{}, { resources: Types.ZMZResource[] }>,
    zmzInsert: {} as QRFunction<{ resource: Types.ZMZResource }, { message: string }>,
    zmzUpdate: {} as QRFunction<{ id: string; oldName: string; resource: Types.ZMZResource }, { message: string }>,
    zmzRemove: {} as QRFunction<{ id: string }, { message: string }>,
    zmzDrop: {} as QRFunction<{}, { message: string }>,

    dmhyRefresh: {} as QRFunction<{}, { resources: Types.DMHYResource[] }>,
    dmhyInsert: {} as QRFunction<{ resource: Types.DMHYResource }, { message: string }>,
    dmhyUpdate: {} as QRFunction<{ id: string; oldName: string; resource: Types.DMHYResource }, { message: string }>,
    dmhyRemove: {} as QRFunction<{ id: string }, { message: string }>,
    dmhyDrop: {} as QRFunction<{}, { message: string }>,

    zmxRefresh: {} as QRFunction<{}, { resources: Types.ZMXResource[] }>,
    zmxInsert: {} as QRFunction<{ resource: Types.ZMXResource }, { message: string }>,
    zmxUpdate: {} as QRFunction<{ id: string; oldName: string; resource: Types.ZMXResource }, { message: string }>,
    zmxRemove: {} as QRFunction<{ id: string }, { message: string }>,
    zmxDrop: {} as QRFunction<{}, { message: string }>,

    commonRefresh: {} as QRFunction<{}, { resources: Types.CommonResource[] }>,
    commonInsert: {} as QRFunction<{ resource: Types.CommonResource }, { message: string }>,
    commonUpdate: {} as QRFunction<{ id: string; oldName: string; resource: Types.CommonResource }, { message: string }>,
    commonRemove: {} as QRFunction<{ id: string }, { message: string }>,
    commonDrop: {} as QRFunction<{}, { message: string }>,

    mcarRefresh: {} as QRFunction<{}, { resources: Types.McarResource[] }>,
    mcarInsert: {} as QRFunction<{ resource: Types.McarResource }, { message: string }>,
    mcarUpdate: {} as QRFunction<{ id: string; oldName: string; resource: Types.McarResource }, { message: string }>,
    mcarRemove: {} as QRFunction<{ id: string }, { message: string }>,
    mcarDrop: {} as QRFunction<{}, { message: string }>,

    linkRefresh: {} as QRFunction<{}, { links: Types.Link[] }>,
    usedAllLink: {} as QRFunction<{}, { message: string }>,
    usedSelectedLinks: {} as QRFunction<{ ids: Array<string> }, { message: string }>,
    usedLink: {} as QRFunction<{ id: string }, { message: string }>,
    linkDrop: {} as QRFunction<{}, { message: string }>,

    scrapAllOnce: {} as QRFunction<{}, {}>,
    scrapOnce: {} as QRFunction<{ type: "all" | "dmhy" | "zmx" | "zmz" | "common" | "mcar"; name?: string }, {}>,

    getLastScrapedTime: {} as QRFunction<{}, { lastScrapedTime: string }>,

    // postMessage: {} as QRFunction<{}, {}>,
};

export var client = {
    increment: {} as QRFunction<{ num: number }, { num: number }>,
};

export var cast = {
    /** for testing */
    // hello: new TypedEvent<{ text: string }>(),

    /** Server quit */
    serverExiting: new TypedEvent<{}>(),

    zmzChanged: new TypedEvent<{ resources: Types.ZMZResource[] }>(),
    dmhyChanged: new TypedEvent<{ resources: Types.DMHYResource[] }>(),
    zmxChanged: new TypedEvent<{ resources: Types.ZMZResource[] }>(),
    commonChanged: new TypedEvent<{ resources: Types.ZMZResource[] }>(),
    mcarChanged: new TypedEvent<{ resources: Types.ZMZResource[] }>(),
    linkChanged: new TypedEvent<{ links: Types.Link[] }>(),
    scrapMessage: new TypedEvent<MessageInfo>(),
    scrapFinished: new TypedEvent<{ lastScrapedTime: string }>(),
};
