import { init, RematchDispatch, RematchRootState } from '@rematch/core';
import createLoadingPlugin from "@rematch/loading";
import immerPlugin from "@rematch/immer";
import selectPlugin from "@rematch/select";
import updatedPlugin from "@rematch/updated";
// import { createLogger } from "redux-logger";
import { models, RootModel } from './models';

// const reduxLogger = createLogger({
//     // ...options
// });

export const store = init({
    redux: {
        // middlewares: [reduxLogger]
    },
    plugins: [
        immerPlugin(),
        createLoadingPlugin({ "type": "number" }),
        selectPlugin({ sliceState: rootState => rootState }),
        updatedPlugin()
    ],
    models,
});

export const select = store.select as any;
export type Store = typeof store;
export type RootDispatch = RematchDispatch<RootModel>;
export type RootState = RematchRootState<RootModel>;
