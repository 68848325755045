import { cast, server } from "../socket/socketClient";
import { createModel } from "@rematch/core";
import { Disposable } from "../common/events";
// import { RootDispatch, RootState } from "../store";
import type { RootModel } from ".";

export type PageState = {
    drawOpen: boolean;
    routeTo: string;
    anchor: "left" | "right";
    confirmOpen: {
        usedAll: boolean;
        dropLink: boolean;
    };
    searchValue: string;
    title: string;
    subtitle: string;
    lastScrapedTime: string;
};

export const page = createModel<RootModel>()({
    state: {
        drawOpen: false,
        routeTo: location.pathname,
        anchor: "left",
        confirmOpen: {
            usedAll: false,
            dropLink: false,
        },
        searchValue: "",
        title: "",
        subtitle: "",
        lastScrapedTime: "",
    } as PageState,
    reducers: {
        sSearchValue(s: PageState, v: string) {
            s.searchValue = v;
            return s;
        },
        openDraw(s: PageState) {
            s.drawOpen = true;
            return s;
        },
        closeDraw(s: PageState) {
            s.drawOpen = false;
            return s;
        },
        sRouteTo(s: PageState, v: string) {
            s.routeTo = v;
            return s;
        },
        openUsedAllConfirm(s: PageState) {
            s.confirmOpen.usedAll = true;
            return s;
        },
        closeUsedAllConfirm(s: PageState) {
            s.confirmOpen.usedAll = false;
            return s;
        },
        openDropLinkConfirm(s: PageState) {
            s.confirmOpen.dropLink = true;
            return s;
        },
        closeDropLinkConfirm(s: PageState) {
            s.confirmOpen.dropLink = false;
            return s;
        },
        sTitle(s: PageState, v: string) {
            s.title = v;
            return s;
        },
        sSubTitle(s: PageState, v: string) {
            s.subtitle = v;
            return s;
        },
        sLastScrapedTime(s: PageState, v: string) {
            s.lastScrapedTime = v;
            s.subtitle = v;
            return s;
        },
    },
    effects: (dispatch) => ({
        async scrapAllOnce() {
            await server.scrapAllOnce({});
        },
        async subscribe() {
            const { links } = await server.linkRefresh({});
            dispatch.link.sResouce(links);

            cast.linkChanged.on((p) => {
                dispatch.link.sResouce(p.links);
            });

            cast.scrapMessage.on((p) => {
                dispatch.message.addMessage(p);
            });
        },
    }),
});
