import { Models } from '@rematch/core'
import { aliyun } from "./aliyun";
import { dmhy } from "./dmhy";
import { link } from "./link";
import { message } from "./message";
import { page } from "./page";
import { theme } from "./theme";
import { zmx } from "./zmx";
import { zmz } from "./zmz";
import { common } from "./common";
import { mcar } from "./mcar";

export interface RootModel extends Models<RootModel> {
	zmz: typeof zmz,
	dmhy: typeof dmhy,
	zmx: typeof zmx,
	common: typeof common,
	mcar: typeof mcar,
	link: typeof link,
	page: typeof page,
	message: typeof message,
	theme: typeof theme,
	aliyun: typeof aliyun
}

export const models: RootModel = { zmz, dmhy, zmx, common, mcar, link, page, message, theme, aliyun }

export { zmz, dmhy, zmx, common, mcar, link, page, message, theme, aliyun };