import { createModel } from "@rematch/core";
import type { RootModel } from ".";
import { MessageInfo } from "../common/message";

// export interface MessageInfo {
//     message?: string;
//     key?: number;
//     type?: MessageType;
// }

export type MessageState = {
    open: boolean;
    queue: MessageInfo[];
    messageInfo: MessageInfo;
};

export const message = createModel<RootModel>()({
    state: {
        open: false,
        queue: [],
        messageInfo: {},
    } as MessageState,
    reducers: {
        // addMessage(s: MessageState, payload: MessageInfo) {
        //     s.queue.push({
        //         message: payload.message,
        //         key: new Date().getTime(),
        //         type: payload.type,
        //     });

        //     if (s.open) {
        //         s.open = false;
        //     } else {
        //         s = this["message/processQueue"](s);
        //     }

        //     return s;
        // },
        sMessage(s: MessageState, payload: MessageInfo) {
            s.queue.push({
                message: payload.message,
                key: new Date().getTime(),
                type: payload.type,
            });

            if (s.open) {
                // s.open = false;
            } else {
                s = this["message/processQueue"](s);
            }

            return s;
        },
        processQueue(s: MessageState) {
            if (s.queue.length > 0) {
                s.messageInfo = s.queue.shift();
                s.open = true;
            } else {
                s.open = false;
            }
            return s;
        },
        open(s: MessageState) {
            s.open = true;
            return s;
        },
        close(s: MessageState) {
            s.open = false;
            return s;
        },
    },
    effects: (dispatch) => ({
        async addMessage(payload: MessageInfo, rootState) {
            switch (payload.type) {
                case "Success":
                    console.log(payload.message);
                    dispatch.message.sMessage(payload);
                    break;
                case "Info":
                    console.info(payload.message);
                    dispatch.message.sMessage(payload);
                    break;
                case "Error":
                    console.error(payload.message);
                    dispatch.message.sMessage(payload);
                    break;
                case "Group":
                    if (payload.groupLabel) {
                        console.group(payload.groupLabel);
                    } else {
                        console.group();
                    }

                    break;
                case "GroupEnd":
                    console.groupEnd();
                    break;
            }
        },
    }),
});
