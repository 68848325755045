import * as React from "react";
import { Theme } from "@mui/material/styles";
import DoneIcon from "@mui/icons-material/Done";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/Button";
import Fab from "@mui/material/Fab";
import { DataGrid, GridActionsCellItem, GridColDef, zhCN } from "@mui/x-data-grid";
import { connect } from "react-redux";
import { RootDispatch, RootState, select } from "../store";
import { withRoot } from "../withRoot";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Link from "./component/link";

const styles = (theme: Theme) => {
    return {
        fab: {
            position: "absolute",
            bottom: theme.spacing(10),
            left: "50%",
            marginLeft: -28,
        },
        dialog: {
            width: 500,
        },
        hidden: {
            position: "absolute",
            overflow: "hidden",
            width: 0,
            height: 0,
            top: 0,
            left: 0,
        },
    };
};

const mapState = (state: RootState) => ({
    columns: state.link.columns,
    links: select.link.selectedLinks(state),
    linkString: select.link.selectedLinkString(state),
    types: state.link.types,
    selectedType: state.link.selectedType,
    lastScrapedTime: state.page.lastScrapedTime,
});

const mapDispatch = (dispatch: RootDispatch) => ({
    subscribe: (f?: () => void) => dispatch.link.subscribe(f),
    unsubscribe: (f?: () => void) => dispatch.link.unSubscribe(f),
    sLinkColumn: (columns: GridColDef[]) => dispatch.link.sColumn(columns),
    sSelectedType: (v: string) => dispatch.link.sSelectedType(v),
    usedSelectedLinks: () => dispatch.link.usedSelectedLinks(undefined),
    usedLink: (id: string) => dispatch.link.usedLink(id),
    sTitle: (title: string) => dispatch.page.sTitle(title),
    sSubTitle: (subtitle: string) => dispatch.page.sSubTitle(subtitle),
});

interface ILinkPageProps {
    classes?: any;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & ILinkPageProps;

@withRoot(styles)
class LinkPage extends React.Component<connectedProps> {
    private textareaAllRef = React.createRef<HTMLTextAreaElement>();
    private textareaRef = React.createRef<HTMLTextAreaElement>();

    constructor(p: connectedProps) {
        super(p);

        this.props.subscribe(this.linkChanged);

        this.props.sTitle("所有订阅");
        this.props.sSubTitle(this.props.lastScrapedTime);
    }

    private linkChanged = () => {
        // if (this.props.columns.length === 0) {
        this.createColumn();
        // }
    };

    createColumn = () => {
        const columns: GridColDef[] = [
            {
                field: "type",
                headerName: "类型",
                width: 400,
                renderHeader: (params) => {
                    return (
                        <Select
                            sx={{
                                width: 320,
                            }}
                            value={this.props.selectedType}
                            onChange={this.handleSelectedTypeChange}
                            inputProps={{
                                name: "selectedType",
                            }}
                        >
                            <MenuItem value="全部">
                                <em>全部</em>
                            </MenuItem>
                            {this.props.types.map((type, i) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                },
                renderCell: (params) => <Link href={params.row.href}>{params.value}</Link>,
            },
            {
                field: "link",
                headerName: "链接",
                flex: 1,
                renderCell: (params) => {
                    const { value } = params;
                    return <Box>{decodeURIComponent(value)}</Box>;
                },
            },
            {
                field: "createdTime",
                headerName: "同步时间",
                width: 250,
            },
            {
                field: "actions",
                type: "actions",
                width: 80,
                renderHeader: (params) => {
                    return (
                        <IconButton color="primary" onClick={() => this.handleUsedSelectedLinks()}>
                            <DoneAllIcon />
                        </IconButton>
                    );
                },
                getActions: (params) => [
                    <GridActionsCellItem icon={<FileCopyIcon color="primary" />} label="Copy" onClick={() => this.handleCopy(params.row.link)} showInMenu={false} onResize={() => { }} onResizeCapture={() => { }} />,
                    <GridActionsCellItem icon={<DoneIcon color="primary" />} label="Delete" onClick={() => this.handleUsedSelectedLink(params.id)} showInMenu={false} onResize={() => { }} onResizeCapture={() => { }} />,
                ],
            } as any,
        ];

        this.props.sLinkColumn(columns);
    };

    private handleSelectedTypeChange = (e) => {
        this.props.sSelectedType(e.target.value);
    };

    private handleUsedSelectedLinks = () => {
        this.props.usedSelectedLinks();
    };

    private handleUsedSelectedLink = (id: string) => {
        this.props.usedLink(id);
    };

    private handleClickCopy = () => {
        this.textareaAllRef.current.select();
        document.execCommand("copy");
    };

    private handleCopy = (v: string) => {
        this.textareaRef.current.value = v;
        this.textareaRef.current.select();
        document.execCommand("copy");
    };

    componentWillUnmount() {
        this.props.unsubscribe();
    }

    render() {
        const { classes, columns, links } = this.props;

        return (
            <>
                <textarea readOnly ref={this.textareaAllRef} value={this.props.linkString} className={classes.hidden} />
                <textarea readOnly ref={this.textareaRef} value="" className={classes.hidden} />
                <DataGrid
                    rows={links}
                    columns={columns}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    disableSelectionOnClick
                    localeText={zhCN.components.MuiDataGrid.defaultProps.localeText}
                    experimentalFeatures={{ newEditingApi: true }}
                    className={classes.datagrid}
                />
                <Fab color="secondary" aria-label="FileCopy" className={classes.fab} onClick={this.handleClickCopy}>
                    <FileCopyIcon />
                </Fab>
            </>
        );
    }
}

export default connect(mapState, mapDispatch)(LinkPage);
