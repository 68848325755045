import { GridColDef } from "@mui/x-data-grid";
import * as Types from "../socket/types";
import { cast, server } from "../socket/socketClient";
import { createModel } from "@rematch/core";
// import { Disposable } from "../common/events";
// import { RootDispatch, RootState } from "../store";
import type { RootModel } from ".";

export type ZMZRState = {
    columns: GridColDef[];
    dialogOpen: boolean;
    resources: Types.ZMZResource[];
    selectedResource: Types.ZMZResource;
    promiseArguments: any;
};

const func = {
    changed: () => {},
    disposable: undefined,
};

export const zmz = createModel<RootModel>()({
    state: {
        columns: [],
        dialogOpen: false,
        resources: [],
        selectedResource: {
            name: "",
            url: "",
        },
        changed: () => {},
        promiseArguments: null,
    } as ZMZRState,
    reducers: {
        sColumn(s: ZMZRState, columns: GridColDef[]) {
            s.columns = [];
            s.columns = columns;
            return s;
        },
        openDialog(s: ZMZRState) {
            s.dialogOpen = true;
            return s;
        },
        closeDialog(s: ZMZRState) {
            s.dialogOpen = false;
            s.selectedResource.name = "";
            return s;
        },
        sName(s: ZMZRState, v: string) {
            s.selectedResource.name = v;
            return s;
        },
        sUrl(s: ZMZRState, v: string) {
            s.selectedResource.url = v;
            return s;
        },
        sResouce(s: ZMZRState, resources: Types.ZMZResource[]) {
            s.resources = resources;
            return s;
        },
        sPromiseArguments(s: ZMZRState, promiseArguments: any) {
            s.promiseArguments = promiseArguments;
            return s;
        },
    },
    effects: (dispatch) => ({
        async subscribe(f: () => void, rootState) {
            if (f) func.changed = f;

            func.disposable = cast.zmzChanged.on((p) => {
                dispatch.zmz.sResouce(p.resources);
                p.resources.length > 0 ? dispatch.page.sSubTitle(p.resources[0].lastUpadedTime) : dispatch.page.sSubTitle("");
                func.changed();
            });

            const { resources } = await server.zmzRefresh({});
            dispatch.zmz.sResouce(resources);
            resources.length > 0 ? dispatch.page.sSubTitle(resources[0].lastUpadedTime) : dispatch.page.sSubTitle("");
            func.changed();
        },
        async unSubscribe(payload: any, rootState) {
            if (func.disposable) {
                func.disposable.dispose();
            }
        },
        async insert(resource: Types.ZMZResource) {
            if (resource.name.trim() === "") {
                dispatch.message.addMessage({
                    message: `错误: 资源名称不能为空`,
                    type: "Error",
                });
            } else {
                await server.zmzInsert({ resource }).then((e) => {
                    dispatch.message.addMessage({
                        message: e.message === "Success" ? "保存成功" : `错误: ${e.message}`,
                        type: e.message === "Success" ? "Success" : "Error",
                    });
                });
            }
        },
        async update(payload: any, rootState) {
            const { resolve, reject, newRow, oldRow } = rootState.zmz.promiseArguments;
            if (newRow.name.trim() === "") {
                dispatch.message.addMessage({
                    message: `错误: 资源名称不能为空`,
                    type: "Error",
                });
                resolve(oldRow);
            } else {
                await server
                    .zmzUpdate({
                        id: newRow.id,
                        oldName: oldRow.name,
                        resource: {
                            name: newRow.name,
                            url: newRow.url,
                        },
                    })
                    .then((e) => {
                        dispatch.message.addMessage({
                            message: e.message === "Success" ? "更新成功" : `错误: ${e.message}`,
                            type: e.message === "Success" ? "Success" : "Error",
                        });
                        e.message === "Success" ? resolve(newRow) : resolve(oldRow);
                    });
            }
        },
        async remove(id: string) {
            await server.zmzRemove({ id }).then((e) => {
                dispatch.message.addMessage({
                    message: e.message === "Success" ? "删除成功" : `错误: ${e.message}`,
                    type: e.message === "Success" ? "Success" : "Error",
                });
            });
        },
        async scrapOnce(name?: string) {
            await server.scrapOnce({ type: "zmz", name });
        },
    }),
});
