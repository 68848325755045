import { createModel } from "@rematch/core";
import { createTheme, Theme } from "@mui/material/styles";
// import { RootDispatch, RootState } from "../store";
import type { RootModel } from '.';

export type ThemeState = {
    value: Theme;
};

export const theme = createModel<RootModel>()({
    state: {
        value: createTheme ({
            palette: {
                mode: "dark",
            },
            typography: {
                // useNextVariants: true,
            },
        }),
    },
    reducers: {
        invertColors(s: ThemeState) {
            // s.value = createTheme ({
            //     palette: {
            //         mode: s.value.palette.mode === "dark" ? "light" : "dark",
            //     },
            //     typography: {
            //         // useNextVariants: true,
            //     },
            // });

            return s;
        },
    },
    effects: (dispatch) => ({
        //
    }),
});
