import { GridColDef } from "@mui/x-data-grid";
import * as Types from "../socket/types";
import { cast, server } from "../socket/socketClient";
import { createModel } from "@rematch/core";
// import { Disposable } from "../common/events";
// import { select, RootDispatch, RootState } from "../store";
import type { RootModel } from ".";

export type LinkState = {
    columns: GridColDef[];
    links: Types.Link[];
    selectedType: string;
    types: string[];
};

const func = {
    changed: () => {},
    disposable: undefined,
};

export const link = createModel<RootModel>()({
    state: {
        columns: [],
        links: [],
        selectedType: "全部",
        changed: () => {},
        types: [],
    } as LinkState,
    reducers: {
        sColumn(s: LinkState, columns: GridColDef[]) {
            s.columns = [];
            s.columns = columns;
            return s;
        },
        sResouce(s: LinkState, links: Types.Link[]) {
            s.links = links;
            s.types = links.reduce((r, v) => {
                if (!r.includes(v.type)) r.push(v.type);
                return r;
            }, []);
            if (s.types.indexOf(s.selectedType) < 0) {
                s.selectedType = "全部";
            }

            return s;
        },
        sSelectedType(s: LinkState, v: string) {
            s.selectedType = v;
            return s;
        },
    },
    selectors: (slice, createSelector, hasProps) => ({
        count() {
            return slice((state: any) => {
                return state.link.links.length;
            });
        },
        selectedLinks() {
            return slice((state: any) => {
                return state.link.links.reduce((r, v) => {
                    if ((state.link.selectedType === "全部" || v.type == state.link.selectedType) && (v.type.indexOf(state.page.searchValue) > -1 || v.link.indexOf(state.page.searchValue) > -1)) r.push(v);
                    return r;
                }, []);
            });
        },
        selectedLinkString() {
            return createSelector(this.selectedLinks, (links: Types.Link[]) => {
                return links
                    .map((l) => {
                        return l.link;
                    })
                    .join("\n");
            });
        },
        // types() {
        //     return slice((state: any) => {
        //         return state.link.links.reduce((r, v) => {
        //             if (!r.includes(v.type)) r.push(v.type);
        //             return r;
        //         }, []);
        //     });
        // },
    }),
    effects: (dispatch) => ({
        async subscribe(f: () => void, rootState) {
            if (f) func.changed = f;

            const scrap = await server.getLastScrapedTime({});
            dispatch.page.sLastScrapedTime(scrap.lastScrapedTime);
            func.disposable = cast.scrapFinished.on((p) => {
                dispatch.page.sLastScrapedTime(p.lastScrapedTime);
            });

            func.changed();
        },
        async unSubscribe(payload: any, rootState) {
            if (func.disposable) {
                func.disposable.dispose();
            }
        },
        async usedAllLink() {
            await server.usedAllLink({}).then((e) => {
                dispatch.message.addMessage({
                    message: e.message,
                    type: e.message === "Success" ? "Success" : "Error",
                });
            });
        },
        async usedSelectedLinks(payload: any, rootState) {
            const ids = rootState.link.links.reduce((r, v) => {
                if ((rootState.link.selectedType == "全部" || v.type == rootState.link.selectedType) && (v.type.indexOf(rootState.page.searchValue) > -1 || v.link.indexOf(rootState.page.searchValue) > -1)) r.push(v.id);
                return r;
            }, []);

            // const ids = select.link.selectedLinks(rootState).map((l) => {
            //     return l.id;
            // });

            await server.usedSelectedLinks({ ids }).then((e) => {
                dispatch.message.addMessage({
                    message: e.message === "Success" ? "标记成功" : `错误: ${e.message}`,
                    type: e.message === "Success" ? "Success" : "Error",
                });
            });
        },
        async usedLink(payload: string, rootState) {
            await server.usedLink({ id: payload }).then((e) => {
                dispatch.message.addMessage({
                    message: e.message === "Success" ? "标记成功" : `错误: ${e.message}`,
                    type: e.message === "Success" ? "Success" : "Error",
                });
            });
        },
        async dropCollection() {
            await server.linkDrop({}).then((e) => {
                dispatch.message.addMessage({
                    message: e.message === "Success" ? "删除成功" : `错误: ${e.message}`,
                    type: e.message === "Success" ? "Success" : "Error",
                });
            });
        },
    }),
});
