import * as React from "react";
import classNames from "classnames";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SquareIcon from "@mui/icons-material/Square";
import { Theme } from "@mui/material/styles";
import { OverridableStringUnion } from "@mui/types";
import { SvgIconPropsColorOverrides } from "@mui/material/SvgIcon";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import { connect } from "react-redux";
import { RootDispatch, RootState } from "../../store";
import { withRoot } from "../../withRoot";

const styles = (theme: Theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    listitem: {
        display: "block",
    },
    listitemButton: {
        minHeight: 48,
        justifyContent: "initial",
        px: 2.5,
    },
    listitemButtonClose: {
        justifyContent: "center",
    },
    listitemIcon: {
        minWidth: 0,
        marginRight: 20,
        justifyContent: "center",
    },
    listitemIconClose: {
        marginRight: "auto",
    },
    listitemText: {
        opacity: 1,
    },
    listitemTextClose: {
        opacity: 0,
    },
    letter: {
        position: "absolute",
        color: "white",
        fontSize: 12,
        marginTop: 4,
    },
});

const mapState = (state: RootState) => ({
    drawOpen: state.page.drawOpen,
    routeTo: state.page.routeTo,
});

const mapDispatch = (dispatch: RootDispatch) => ({
    sRouteTo: (to: string) => dispatch.page.sRouteTo(to),
});

interface IListItemLinkProps extends RouterLinkProps {
    classes?: any;
    icon?: React.ReactElement;
    letter?: string;
    primary: string;
    to: string;
    color?: OverridableStringUnion<"inherit" | "action" | "disabled" | "primary" | "secondary" | "error" | "info" | "success" | "warning", SvgIconPropsColorOverrides>;
}

type connectedProps = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IListItemLinkProps;

@withRoot(styles)
class ListItemLink extends React.Component<connectedProps> {
    constructor(p: connectedProps) {
        super(p);
    }

    handleRouteTo = (to: string) => {
        this.props.sRouteTo(to);
    };

    render() {
        const { classes, routeTo, icon, primary, to, color, letter } = this.props;

        const renderLink = React.forwardRef((props, ref) => <RouterLink to={to} ref={ref as any} {...props} onClick={() => this.handleRouteTo(to)} />);

        return (
            <ListItem button key={primary} disablePadding component={renderLink} selected={routeTo === to}>
                <ListItemButton className={classNames(classes.listitemButton, !this.props.drawOpen && classes.listitemButtonClose)}>
                    <ListItemIcon className={classNames(classes.listitemIcon, !this.props.drawOpen && classes.listitemIconClose)}>
                        {icon ? (
                            icon
                        ) : (
                            <>
                                <SquareIcon color={color} />
                                <span className={classes.letter}>{letter}</span>
                            </>
                        )}
                    </ListItemIcon>
                    <ListItemText primary={primary} className={classNames(classes.listitemText, !this.props.drawOpen && classes.listitemTextClose)} />
                </ListItemButton>
            </ListItem>
        );
    }
}

export default connect(mapState, mapDispatch)(ListItemLink);
