import * as Types from "../socket/types";

export function getZMZLink(r: Types.ZMZResource) {
    return r.url;
}

export function getDMHYLink(r: Types.DMHYResource) {
    return `https://share.dmhy.org/topics/list?keyword=${r.key}`;
}

export function getZMXLink(r: Types.ZMXResource) {
    return `http://www.zimuxia.cn/portfolio/${r.name}`;
}

export function getCommonLink(r: Types.CommonResource) {
    return r.url;
}

export function getMcarLink(r: Types.McarResource) {
    return `https://mcar.vip/forum.php?mod=viewthread&authorid=${r.authorid}&tid=${r.tid}`;
}